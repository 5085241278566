import { gql } from '@apollo/client';

import { FRAGMENT_TWILIO_METADATA } from 'modules/graphql/fragments';

import {
	FRAGMENT_CUSTOMER_DATA,
	FRAGMENT_AGENT_DATA,
	FRAGMENT_TEAM_DATA
} from './fragments';

const QUERY_MAIN_LAYOUT = gql`
	query QueryMainLayout($agentId: ID!) {
		agent(id: $agentId) {
			id
			name
			phone
			email
			isBroker
			isTeamLead
			isZendesk
			hasHolmesApp
			allowMailgun
			totalCustomersCount
			clearbit {
				avatar
				facebook
				twitter
				linkedin
			}
			integrations {
				twilio {
					...TwilioIntegration
				}
				webchat {
					appId
				}
				email {
					forwardingEmail
				}
			}
		}
	}
	${FRAGMENT_TWILIO_METADATA}
`;

const QUERY_AGENT_MAIN_INFO = gql`
	query QueryAgentMainInfo($agentId: ID!) {
		agent(id: $agentId) {
			id
			name
			email
			clearbit {
				avatar
				facebook
				twitter
				linkedin
			}
			allowCalendlyScheduling
			allowCustomScripts
			allowHardAppointmentScheduling
			allowIntercom
			allowMailgun
			allowMultiConversations
			allowMultiSmsChannels
			allowPlatformDialer
			allowPlatformDialerExperimentalSettings
			allowVoiceChannels
			allowAiVoiceAgents
			allowAiTextAgents
			allowMessagingChannels
			multiConversationsCanBeActivated
			twilioVoiceGrant
			callDispositions
			settings {
				chatbotDomains
			}
			customScripts {
				id
				name
			}
			textAiAgents {
				id
				name
			}
			digitalVoiceAssistants {
				id
				useCase
				isEnabled
			}
			customReportCsvs {
				id
			}
		}
		voiceProviders {
			id
			name
			gender
			style
			isDefault
			provider
		}
	}
`;

const QUERY_TEAM_MAIN_INFO = gql`
	query QueryTeamMainInfo($teamId: ID!) {
		team(id: $teamId) {
			id
			name
			allowCalendlyScheduling
			allowCustomScripts
			allowHardAppointmentScheduling
			allowIntercom
			allowMailgun
			allowMultiConversations
			allowMultiSmsChannels
			allowPlatformDialer
			allowPlatformDialerExperimentalSettings
			allowVoiceChannels
			allowAiVoiceAgents
			allowAiTextAgents
			allowMessagingChannels
			multiConversationsCanBeActivated
			callDispositions
			settings {
				chatbotDomains
			}
			customScripts {
				id
				name
			}
			textAiAgents {
				id
				name
			}
			digitalVoiceAssistants {
				id
				useCase
				isEnabled
			}
			customReportCsvs {
				id
			}
		}
		voiceProviders {
			id
			name
			gender
			style
			isDefault
			provider
		}
	}
`;

const QUERY_BROKERAGE_MAIN_INFO = gql`
	query QueryBrokeageMainInfo($brokerageId: ID!) {
		brokerage(id: $brokerageId) {
			id
			name
			allowCalendlyScheduling
			allowCustomScripts
			allowHardAppointmentScheduling
			allowIntercom
			allowMailgun
			allowMultiConversations
			allowMultiSmsChannels
			allowPlatformDialer
			allowPlatformDialerExperimentalSettings
			allowVoiceChannels
			allowAiVoiceAgents
			allowAiTextAgents
			allowMessagingChannels
			multiConversationsCanBeActivated
			callDispositions
			settings {
				chatbotDomains
			}
			customScripts {
				id
				name
			}
			textAiAgents {
				id
				name
			}
			digitalVoiceAssistants {
				id
				useCase
				isEnabled
			}
			customReportCsvs {
				id
			}
		}
		voiceProviders {
			id
			name
			gender
			style
			isDefault
			provider
		}
	}
`;

const QUERY_AGENT_NAME = gql`
	query QueryAgentName($agentId: ID!) {
		agent(id: $agentId) {
			id
			name
			agencyName
			isZendesk
		}
	}
`;

const QUERY_AGENT_DOMAINS = gql`
	query QueryAgentDomains($id: ID!) {
		agent(id: $id) {
			id
			settings {
				chatbotDomains
			}
		}
	}
`;

const QUERY_AGENT_INTERCOM_INFO = gql`
	query QueryAgentIntercomInfo($agentId: ID!) {
		agent(id: $agentId) {
			id
			name
			phone
			email
			hasHolmesApp
			isZendesk
			allowMailgun
			integrations {
				twilio {
					...TwilioIntegration
				}
				webchat {
					appId
				}
				email {
					forwardingEmail
				}
			}
		}
	}
	${FRAGMENT_TWILIO_METADATA}
`;

const QUERY_TEAM_NAME = gql`
	query QueryTeamName($teamId: ID!) {
		team(id: $teamId) {
			id
			name
			isZendesk
		}
	}
`;

const QUERY_TEAM_DOMAINS = gql`
	query QueryTeamDomains($id: ID!) {
		team(id: $id) {
			id
			settings {
				chatbotDomains
			}
		}
	}
`;

const QUERY_TEAM_INTEGRATIONS = gql`
	query QueryTeamIntegrations($teamId: ID!) {
		team(id: $teamId) {
			id
			name
			allowMailgun
			integrations {
				twilio {
					...TwilioIntegration
				}
				webchat {
					appId
				}
				email {
					forwardingEmail
				}
			}
		}
	}
	${FRAGMENT_TWILIO_METADATA}
`;

const QUERY_BROKERAGE_NAME = gql`
	query QueryBrokerageName($brokerageId: ID!) {
		brokerage(id: $brokerageId) {
			id
			name
			isZendesk
		}
	}
`;

const QUERY_BROKERAGE_DOMAINS = gql`
	query QueryBrokerageDomains($id: ID!) {
		brokerage(id: $id) {
			id
			settings {
				chatbotDomains
			}
		}
	}
`;

const QUERY_BROKERAGE_INTEGRATIONS = gql`
	query QueryBrokerageIntegrations($brokerageId: ID!) {
		brokerage(id: $brokerageId) {
			id
			name
			allowMailgun
			integrations {
				twilio {
					...TwilioIntegration
				}
				webchat {
					appId
				}
				email {
					forwardingEmail
				}
			}
		}
	}
	${FRAGMENT_TWILIO_METADATA}
`;

const QUERY_TEAM_MEMBERS = gql`
	query QueryTeamMembers($teamId: ID!) {
		team(id: $teamId) {
			id
			name
			totalCustomersCount
			agents {
				...AgentData
			}
		}
	}

	${FRAGMENT_AGENT_DATA}
`;

const QUERY_BROKERAGE_MEMBERS = gql`
	query QueryBrokerageMembers($brokerageId: ID!) {
		brokerage(id: $brokerageId) {
			id
			name
			totalCustomersCount
			agents {
				...AgentData
			}
			teams {
				id
				name
				agents {
					...AgentData
				}
			}
		}
	}

	${FRAGMENT_AGENT_DATA}
`;

const QUERY_BROKERAGE_TEAMS = gql`
	query QueryBrokerageTeams($brokerageId: ID!) {
		brokerage(id: $brokerageId) {
			id
			name
			teams {
				...TeamData
				agents {
					...AgentData
				}
			}
		}
	}

	${FRAGMENT_TEAM_DATA}
	${FRAGMENT_AGENT_DATA}
`;

const QUERY_AGENT_SOCIAL = gql`
	query QueryAgentAvatar($agentId: ID!) {
		agent(id: $agentId) {
			id
			clearbit {
				avatar
				facebook
				twitter
				linkedin
			}
		}
	}
`;

const QUERY_LEAD_SOURCES = gql`
	query QueryLeadSources($agentId: ID!) {
		agent(id: $agentId) {
			id
			integrations {
				webchat {
					appId
				}
				twilio {
					...TwilioIntegration
				}
				email {
					forwardingEmail
				}
			}
		}
	}
	${FRAGMENT_TWILIO_METADATA}
`;

const QUERY_AGENT_CUSTOMERS = gql`
	query QueryAgentCustomers(
		$id: ID!
		$offset: Int
		$limit: Int
		$assigned: Boolean
		$archived: Boolean
		$stages: [String]
		$sources: [String]
		$callDispositions: [String]
		$search: String
		$muted: Boolean
		$customerEngaged: Boolean
		$agentEngaged: Boolean
		$sort: String
		$filter: JSONString
		$cursor: CursorInputType
	) {
		agent(id: $id) {
			id
			totalCustomersCount(
				assigned: $assigned
				archived: $archived
				sources: $sources
				stages: $stages
				callDispositions: $callDispositions
				search: $search
				muted: $muted
				customerEngaged: $customerEngaged
				agentEngaged: $agentEngaged
				filter: $filter
			)
			customers(
				offset: $offset
				limit: $limit
				assigned: $assigned
				archived: $archived
				stages: $stages
				callDispositions: $callDispositions
				sources: $sources
				search: $search
				muted: $muted
				customerEngaged: $customerEngaged
				agentEngaged: $agentEngaged
				sort: $sort
				filter: $filter
				cursor: $cursor
			) {
				...CustomerData
			}
		}
	}

	${FRAGMENT_CUSTOMER_DATA}
`;

const QUERY_TEAM_CUSTOMERS = gql`
	query QueryTeamCustomers(
		$id: ID!
		$offset: Int
		$limit: Int
		$assigned: Boolean
		$archived: Boolean
		$stages: [String]
		$sources: [String]
		$callDispositions: [String]
		$search: String
		$muted: Boolean
		$customerEngaged: Boolean
		$agentEngaged: Boolean
		$sort: String
		$filter: JSONString
		$cursor: CursorInputType
	) {
		team(id: $id) {
			id
			totalCustomersCount(
				assigned: $assigned
				archived: $archived
				stages: $stages
				sources: $sources
				callDispositions: $callDispositions
				search: $search
				muted: $muted
				customerEngaged: $customerEngaged
				agentEngaged: $agentEngaged
				filter: $filter
			)
			customers(
				offset: $offset
				limit: $limit
				assigned: $assigned
				archived: $archived
				stages: $stages
				sources: $sources
				callDispositions: $callDispositions
				search: $search
				muted: $muted
				customerEngaged: $customerEngaged
				agentEngaged: $agentEngaged
				sort: $sort
				filter: $filter
				cursor: $cursor
			) {
				...CustomerData
			}
		}
	}

	${FRAGMENT_CUSTOMER_DATA}
`;

const QUERY_BROKERAGE_CUSTOMERS = gql`
	query QueryBrokerageCustomers(
		$id: ID!
		$offset: Int
		$limit: Int
		$assigned: Boolean
		$archived: Boolean
		$stages: [String]
		$sources: [String]
		$callDispositions: [String]
		$search: String
		$muted: Boolean
		$customerEngaged: Boolean
		$agentEngaged: Boolean
		$sort: String
		$filter: JSONString
		$cursor: CursorInputType
	) {
		brokerage(id: $id) {
			id
			totalCustomersCount(
				assigned: $assigned
				archived: $archived
				stages: $stages
				sources: $sources
				callDispositions: $callDispositions
				search: $search
				muted: $muted
				customerEngaged: $customerEngaged
				agentEngaged: $agentEngaged
				filter: $filter
			)
			customers(
				offset: $offset
				limit: $limit
				assigned: $assigned
				archived: $archived
				stages: $stages
				sources: $sources
				callDispositions: $callDispositions
				search: $search
				muted: $muted
				customerEngaged: $customerEngaged
				agentEngaged: $agentEngaged
				sort: $sort
				filter: $filter
				cursor: $cursor
			) {
				...CustomerData
			}
		}
	}

	${FRAGMENT_CUSTOMER_DATA}
`;

export const QUERY_BUILTIN_TAGS = gql`
	query QueryBuiltinTags($domains: [Domain]) {
		builtinTags(domains: $domains) {
			name
			value
			domains
			leadTypes
		}
	}
`;

export const QUERY_BUILTIN_LEAD_TYPES = gql`
	query QueryBuiltinLeadTypes($domains: [Domain]) {
		leadTypes(domains: $domains)
	}
`;

export {
	QUERY_MAIN_LAYOUT,
	QUERY_AGENT_MAIN_INFO,
	QUERY_TEAM_MAIN_INFO,
	QUERY_BROKERAGE_MAIN_INFO,
	QUERY_AGENT_NAME,
	QUERY_AGENT_DOMAINS,
	QUERY_AGENT_INTERCOM_INFO,
	QUERY_TEAM_NAME,
	QUERY_TEAM_DOMAINS,
	QUERY_TEAM_INTEGRATIONS,
	QUERY_BROKERAGE_NAME,
	QUERY_BROKERAGE_DOMAINS,
	QUERY_BROKERAGE_INTEGRATIONS,
	QUERY_TEAM_MEMBERS,
	QUERY_BROKERAGE_MEMBERS,
	QUERY_BROKERAGE_TEAMS,
	QUERY_AGENT_SOCIAL,
	QUERY_LEAD_SOURCES,
	QUERY_AGENT_CUSTOMERS,
	QUERY_TEAM_CUSTOMERS,
	QUERY_BROKERAGE_CUSTOMERS
};
